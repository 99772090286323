import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';
import { Code } from '@uc-common/code';

import { Paragraph } from './shared';
import { H1, H2 } from './styled_components';

const StyledCode = styled(Code)`
  margin-bottom: 10px;
`;

const integrationSnippet = `
<script>
   (function(src, cb) {
    var s = document.createElement('script'); s.setAttribute('src', src);
    s.onload = cb; (document.head || document.body).appendChild(s);
  })('https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js', function() {
    window.Blinkloader.optimize({
      pubkey:'YOUR_PUBLIC_KEY',
      fadeIn:true,
      lazyload:true,
      smartCompression:true,
      responsive:true,
      retina:true,
      webp:true
    });
  })
</script>`;

const pictureSnippet = `
<picture>
   <source srcset="https://image-host.com/1000.jpg 1000w,
                   https://image-host.com/2000.jpg 2000w"
           sizes="(min-width: 1000px) 60vw, 100vw"
           media="(min-width: 750px)">

   <source srcset="https://image-host.com/750.jpg 1x,
                   https://image-host.com/1500.jpg 2x"
           media="(min-width: 450px)">

   <source srcset="https://image-host.com/450.jpg 1x,
                   https://image-host.com/900.jpg 2x"
           media="(orientation: landscape)">

   <source srcset="https://image-host.com/450-800.jpg 1x,
                 https://image-host.com/900-1600.jpg 2x"
           media="(orientation: portrait)">

   <img src="https://image-host.com/fallback.jpg" />
</picture>`;

export const IntegrationSummary = () => {
  return (
    <Flex py={60} maxWidth={980} width="100%" flexDirection="column">
      <H1>Integration</H1>
      <Paragraph mb={60}>
        Integrating Adaptive Delivery is a simple, two-step process. First, you load our
        lightweight, 3.4kB SDK in your head tag and then modify the source of your image tags to let
        the SDK know that it should optimize them.
      </Paragraph>
      <div style={{ marginBottom: '60px' }}>
        <H2>Load the SDK:</H2>
        <Code code={integrationSnippet} language="html" />
      </div>
      <H2>Replace the source of image tags:</H2>
      <Flex maxWidth={980} width="100%" justifyContent="space-between" flexWrap="wrap" mb={60}>
        <StyledCode code={`<img src="https://image-host.com/image.jpg" />`} language="html" />
        <StyledCode
          code={`<img data-blink-src="https://image-host.com/image.jpg" />`}
          language="html"
        />
      </Flex>
      <div>
        <H2>Remove traditional responsive image tags:</H2>
        <Code code={pictureSnippet} language="html" />
      </div>
    </Flex>
  );
};
