import React from 'react';
import styled from 'styled-components';

const StyledToggle = styled.div`
  margin: 20px 0 20px 0;

  .button-toggle-wrap {
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .button-toggle {
    display: inline-block;
    background: #bbbbbb;
    border-radius: 3px;
    height: 48px;
    padding: 4px;
    width: 101px;
    position: relative;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.22);
  }

  .button-toggle .handle {
    position: absolute;
    left: 4px;
    right: auto;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 3px;
    transition: all 0.4s cubic-bezier(0.33, 1.6, 0.66, 1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.22);
  }

  .toggler {
    display: none;
  }

  .toggler:checked + .button-toggle {
    background: rgb(225, 224, 255);
  }

  .toggler:checked + .button-toggle .handle {
    left: 57px;
  }
`;

export const AdaptiveToggle = ({ adaptive, toggleHandler }) => {
  return (
    <StyledToggle>
      <label htmlFor="adaptive-toggle" className="button-toggle-wrap">
        <input
          id="adaptive-toggle"
          onChange={toggleHandler}
          className="toggler"
          type="checkbox"
          checked={adaptive}
          data-toggle="button-toggle"
        />
        <div className="button-toggle">
          <div className="handle">
            <div className="bars" />
          </div>
        </div>
      </label>
    </StyledToggle>
  );
};
