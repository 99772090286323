export const UC_CDN_BASE = 'https://ucarecdn.com/';
const BASE_CDN_OPERATION = '/-/format/auto/-/quality/smart/';

export const processUCSource = ({ uuid, src }) => {
  const processUuid = (uuid) => UC_CDN_BASE + uuid + BASE_CDN_OPERATION;

  const getFileName = (url) => {
    const hasFileName = url.includes('.');
    if (!hasFileName) return '';
    return url.substring(1 + url.lastIndexOf('/')) || '';
  };

  const processSrc = (src) => {
    // CDN can't use operation for: svg, gif and base64
    const cantUseCdnOperations =
      src?.includes('svg') || src?.includes('gif') || src?.includes('data:');

    if (cantUseCdnOperations) return src;

    const url = new URL(src);
    const fileName = getFileName(url.pathname);
    const pathName = [url.pathname.replace(fileName, ''), BASE_CDN_OPERATION, fileName]
      .join('/')
      // remove double slash in path
      .replace(/\/+/g, '/');

    return url.origin + pathName;
  };

  if (uuid) {
    return processUuid(uuid);
  }

  if (src) {
    return processSrc(src);
  }
};
