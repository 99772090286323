import React from 'react';
import { Flex } from 'rebass/styled-components';
import { InfoImage } from './info_image';
import { H2 } from './styled_components';

export const SingleImageComparison = () => {
  return (
    <Flex maxWidth={980} width="100%" justifyContent="space-between" py={60}>
      <div style={{ flexBasis: '45%' }}>
        <H2>Original Image:</H2>
        <InfoImage src="https://ucarecdn.com/eac5a08e-a60b-4407-a777-98f6d4e8d4a6/" />
      </div>
      <div style={{ flexBasis: '45%' }}>
        <H2>Adaptive Image:</H2>
        <InfoImage adaptive src="https://ucarecdn.com/eac5a08e-a60b-4407-a777-98f6d4e8d4a6/" />
      </div>
    </Flex>
  );
};
