import frmBts from 'pretty-bytes';
import React, { useMemo } from 'react';
import { InfoBlock, InfoList, ItemLabel } from './styled_components';

export const MultipleInfo = ({ format, totalSize, numberOfImages }) => {
  const size = useMemo(() => frmBts(totalSize), [totalSize]);
  return (
    <InfoBlock>
      <InfoList>
        <li>
          <ItemLabel>number of images: </ItemLabel>
          {numberOfImages || ''}
        </li>
        <li>
          <ItemLabel>image formats:</ItemLabel> {format}
        </li>
        <li>
          <ItemLabel>total size: </ItemLabel>
          <span style={{ whiteSpace: 'nowrap' }}>{totalSize ? size : ''}</span>
        </li>
      </InfoList>
    </InfoBlock>
  );
};
