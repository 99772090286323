import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: var(--font-headings);
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;
  letter-spacing: 0.004px;
  margin: 0 0 20px 0;
`;

export const H2 = styled.h3`
  letter-spacing: 0.004px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 20px;
`;

export const UnorderedList = styled.ul`
  letter-spacing: 0.004px;
  font-size: 20px;
  line-height: 32px;
  color: #626262;
  font-weight: 400;
  margin-left: 50px;
  margin-bottom: 20px;
  max-width: 750px;
`;

export const InfoList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  font-family: var(--font-mono);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;

  color: #444444;

  letter-spacing: 0.004px;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InfoHeader = styled.h3`
  font-family: var(--font-mono);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004px;
  margin: 0 0 5px;

  color: #b5b5b5;
`;

export const ItemLabel = styled.span`
  color: #bbbbbb;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
  opacity: 0.5;
`;
