import React from 'react';
import { isBrowser } from '../utils';
import { NoScriptImage } from './NoScriptImage';
import { processUCSource } from './utils';

// TODO: remove
export const BlImage = ({ alt, ...props }) => {
  const uuid = props['data-blink-uuid'];
  const src = props['data-blink-src'];
  const noJsSrc = processUCSource({ uuid, src });

  // render blink image only in browser because Blinkloader work only in browser
  return (
    <>
      {isBrowser() && <img {...props} data-blink-src={src} data-blink-uuid={uuid} alt={alt} />}
      <NoScriptImage src={noJsSrc} alt={alt} {...props} />
    </>
  );
};
