import React from 'react';
import { Flex } from 'rebass/styled-components';
import { CommonLayout } from '../../components/Layout/CommonLayout';
import { Navbar } from '../../components/Navbar';
import {
  HorizontalLine,
  AdaptiveDescription,
  SingleImageComparison,
  MultipleImageComparison,
  IntegrationSummary,
} from '../../components/forAdaptiveDeliveryDemo';

export const frontmatter = {
  title: 'Adaptive Delivery Demo',
  description:
    "A demo of Uploadcare's Adaptive Delivery. Adaptive Delivery is Uploadcare's fully-automated image optimization solution for the web supporting image reformatting, resizing, compression and lazy loading.",
  noindex: true,
};

const AdaptiveDelivery = ({ location }) => (
  <CommonLayout meta={frontmatter} pathName={location.pathname}>
    <Navbar type="custom" visual="light" />
    <main style={{ paddingBottom: '200px' }}>
      <Flex alignItems="center" flexDirection="column" pb={[5, 0]}>
        <AdaptiveDescription />
        <HorizontalLine />
        <SingleImageComparison />
        <HorizontalLine />
        <MultipleImageComparison location={location} />
        <HorizontalLine />
        <IntegrationSummary />
      </Flex>
    </main>
  </CommonLayout>
);

export default AdaptiveDelivery;
