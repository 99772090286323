import React, { useState } from 'react';
import { BlImage } from '@uc-common/bl-image';
import { Info } from './info';

export const InfoImage = ({ src, adaptive }) => {
  const [imageInfo, setImageInfo] = useState();

  const onLoad = (e) => {
    const imageEl = e.target;
    const xhr = new XMLHttpRequest();
    xhr.path = e.target.src;
    xhr.open('GET', e.target.src, true);
    xhr.setRequestHeader('Accept', 'image/webp');
    xhr.responseType = 'arraybuffer';
    xhr.onload = (e) => {
      // Filter out 1 pixel PNG placeholder
      if (e.target.status === 200 && e.target.response.byteLength > 200) {
        setImageInfo({
          displayWidth: imageEl.width,
          displayHeight: imageEl.height,
          intrinsicWidth: imageEl.naturalWidth,
          intrinsicHeight: imageEl.naturalHeight,
          format: xhr.getResponseHeader('Content-Type').slice(6),
          byteSize: e.target.response.byteLength,
        });
      }
    };
    xhr.send();
  };

  return (
    <>
      {adaptive ? (
        <BlImage data-blink-src={src} onLoad={onLoad} style={{ width: '100%' }} />
      ) : (
        <img src={src} onLoad={onLoad} style={{ width: '100%' }} alt="" />
      )}

      {imageInfo && <Info {...imageInfo} />}
    </>
  );
};
