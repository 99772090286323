import React from 'react';
import { Box, Text } from 'rebass/styled-components';

export const Paragraph = (props) => (
  <Box maxWidth={750}>
    <Text
      as="p"
      letterSpacing="0.004px"
      fontSize="20px"
      lineHeight="32px"
      color="#626262"
      fontWeight="400"
      {...props}
    />
  </Box>
);
