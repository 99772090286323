import React from 'react';
import { Flex } from 'rebass/styled-components';
import { Paragraph } from './shared';
import { H1, UnorderedList } from './styled_components';

export const AdaptiveDescription = () => {
  return (
    <Flex py={60} maxWidth={980} width="100%" flexDirection="column">
      <H1>Adaptive Delivery</H1>
      <Paragraph mb={10}>
        Adaptive Delivery is a fully-automated image optimization solution for the web. In a single
        SDK, it performs all four of the major image optimizations:
      </Paragraph>
      <UnorderedList>
        <li>Opportunistically reformats images to use next-generation formats like WebP</li>
        <li>Responsively resizes images down to the exact size they are displayed at</li>
        <li>Dynamically compresses images based on the visitor device&apos;s DPI</li>
        <li>Lazy loads images to reduce page load times</li>
      </UnorderedList>
      <Paragraph mb="0px">
        These optimizations reliably reduce total image weight by 40-90% while eliminating the need
        for complex responsive image tags and image processing systems.
      </Paragraph>
    </Flex>
  );
};
