import React, { useState, useEffect } from 'react';
import { Flex } from 'rebass/styled-components';
import { BlImage } from '@uc-common/bl-image';
import { H2 } from './styled_components';
import { MultipleInfo } from './multiple_info';
import { AdaptiveToggle } from './adaptive_toggle';

const imageSrcs = [
  'https://ucarecdn.com/fbfb7c0d-d6e7-4b95-b794-4a6ffbb1033a/',
  'https://ucarecdn.com/544db55e-0db1-4ee2-ae59-09f26ecb454f/',
  'https://ucarecdn.com/de8dea2b-f173-4e5c-b82e-24d0bca50afb/',
  'https://ucarecdn.com/0536c365-8a42-4ecf-ad66-dd382392236c/',
  'https://ucarecdn.com/2e7f40a6-e896-4c37-b02f-4a50d0908fa7/',
  'https://ucarecdn.com/09ab9ab4-3c65-45f6-9937-772e2f1c60fb/',
  'https://ucarecdn.com/e0ce62ff-a655-46a2-b7a0-8ae719221b35/',
  'https://ucarecdn.com/fc8aa545-2a6e-440b-b7fb-e4ab867a2f81/',
  'https://ucarecdn.com/40864dc2-7f38-4c63-98f8-f2e83d22dcf1/',
  'https://ucarecdn.com/b4b24f5c-b811-4bcf-a328-9d43a1b3fece/',
  'https://ucarecdn.com/949d2e88-67f0-4a99-b2e4-384875225fe8/',
  'https://ucarecdn.com/44d93b12-0511-4309-87bd-fb4230b5a981/',
];

const fetchImageInfo = (src, setter) => {
  const xhr = new XMLHttpRequest();
  xhr.path = src;
  xhr.open('GET', src, true);
  xhr.setRequestHeader('Accept', 'image/webp');
  xhr.responseType = 'arraybuffer';
  xhr.onload = (e) => {
    // Filter out 1 pixel PNG placeholder
    if (e.target.status === 200 && e.target.response.byteLength > 200) {
      setter((prevInfo) => ({
        totalSize: prevInfo.totalSize + e.target.response.byteLength,
        numberOfImages: prevInfo.numberOfImages + 1,
        format: xhr.getResponseHeader('Content-Type').slice(6),
      }));
    }
  };
  xhr.send();
};

export const MultipleImageComparison = ({ location }) => {
  const [adaptive, setAdaptive] = useState(() => location.search === '?adaptive');
  const [originalImageInfo, setOriginalImageInfo] = useState({
    totalSize: 0,
    numberOfImages: 0,
    format: '',
  });
  const [adaptiveImageInfo, setAdaptiveImageInfo] = useState({
    totalSize: 0,
    numberOfImages: 0,
    format: '',
  });

  useEffect(() => {
    imageSrcs.forEach((src) => {
      fetchImageInfo(src, setOriginalImageInfo);
    });
  }, []);

  const onAdaptiveLoad = (e) => {
    fetchImageInfo(e.target.src, setAdaptiveImageInfo);
  };

  const toggleHandler = (e) => {
    setAdaptive(e.target.checked);
    setAdaptiveImageInfo({
      totalSize: 0,
      numberOfImages: 0,
      format: '',
    });
    if (e.target.checked) {
      window.history.pushState({}, '', '?adaptive');
    } else {
      window.history.pushState({}, '', location.origin + location.pathname);
    }
  };

  return (
    <>
      <Flex maxWidth={980} width="100%" justifyContent="space-between" py={60}>
        <div style={{ flexBasis: '45%' }}>
          <H2>Original Images:</H2>
          <MultipleInfo {...originalImageInfo} />
        </div>
        <Flex
          style={{ flexBasis: '45%' }}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <div>
            <H2>Adaptive Images:</H2>
            <MultipleInfo {...adaptiveImageInfo} />
          </div>
          <AdaptiveToggle adaptive={adaptive} toggleHandler={toggleHandler} />
        </Flex>
      </Flex>
      <Flex
        maxWidth={980}
        width="100%"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="flex-start"
        pb={80}
      >
        {imageSrcs.map((src) =>
          adaptive ? (
            <BlImage
              style={{ flexBasis: '33.333333%', width: '33.333333%' }}
              data-blink-src={src}
              onLoad={onAdaptiveLoad}
              key={src}
            />
          ) : (
            <img
              style={{ flexBasis: '33.333333%', width: '33.333333%' }}
              src={src}
              key={src}
              alt=""
            />
          )
        )}
      </Flex>
    </>
  );
};
