import frmBts from 'pretty-bytes';
import React, { useMemo } from 'react';
import { InfoBlock, InfoList, ItemLabel } from './styled_components';

export const Info = ({
  format,
  displayWidth,
  displayHeight,
  intrinsicWidth,
  intrinsicHeight,
  byteSize,
}) => {
  const size = useMemo(() => frmBts(byteSize), [byteSize]);
  return (
    <InfoBlock>
      <InfoList>
        <li>
          <ItemLabel>format:</ItemLabel> {format}
        </li>
        <li>
          <ItemLabel>image dimensions: </ItemLabel>
          <span style={{ whiteSpace: 'nowrap' }}>
            {intrinsicWidth} x {intrinsicHeight} px
          </span>
        </li>
        <li>
          <ItemLabel>display dimensions: </ItemLabel>
          <span style={{ whiteSpace: 'nowrap' }}>
            {displayWidth} x {displayHeight} px
          </span>
        </li>
        <li>
          <ItemLabel>size:</ItemLabel> {size}
        </li>
      </InfoList>
    </InfoBlock>
  );
};
